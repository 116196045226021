<template>
  <!-- #site-wrapper start -->
  <div
    v-if="user"
    id="site-wrapper"
    class="site-wrapper panel page-invoice-listing"
  >
    <!-- #header start -->
    <!-- #header start -->
    <NavBar />
    <!-- #header end -->
    <!-- #wrapper-content start -->
    <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
      <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">
        <LeftSideBar />

        <div class="page-container">
          <div class="container-fluid">
            <div class="page-content-wrapper d-flex flex-column h-100">
              <h1 class="font-size-h4 mb-4 font-weight-normal">
                New Subscription
              </h1>
              <div class="page-content">
               
               <ChooseNewSubscription v-bind:redirect-url-name="'MySubscription'"  v-bind:hide-default-subscription="true" />
        
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <FooterBar />
    <!-- #wrapper-content end -->
  </div>

  <div v-else>
    <LoadingScreen />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NavBar from "../Layouts/NavBar";
import LeftSideBar from "../Layouts/LeftSideBar";
import FooterBar from "../Layouts/FooterBar";
import LoadingScreen from "../Layouts/LoadingScreen";
import ChooseNewSubscription from "../MySubscription/ChooseNewSubscription"
export default {
  name: "MySubscription",

  components: {
    NavBar,
    LeftSideBar,
    FooterBar,
    ChooseNewSubscription,
    LoadingScreen,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("business", ["currentBusiness"]),
  },
  mounted() {
    this.getPartnerBusiness(this.$route.params.partnerId);
  },
  methods: {
    ...mapActions("auth"),
    ...mapActions("business", ["getPartnerBusiness"]),
    createNewSubscription(){
       this.$router.push({
        name: "CreateNewSubscription",
        params: {
          partnerId: this.$route.params.partnerId,
        },
      });
    }
  },
};
</script>